// import setBeforeAfterSlider from "./_modules/setBeforeAfterSlider";
// import setCaseSliderSp from "./_modules/setCaseSliderSp";
// import setFaqToggle from "./_modules/setFaqToggle";
import setPhotoSlider from "./_modules/setPhotoSlider";
// import setDrSliderSp from "./_modules/setDrSliderSp";
import FtopBtn from "./_modules/footerTopBtn";
// import setAccordion from "./_modules/setAccordion";

window.addEventListener('load', () => {
  // setCaseSliderSp();
  // setFaqToggle();
  setPhotoSlider();
  // setDrSliderSp();
  FtopBtn();
  // setAccordion();
})