import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const FtopBtn = () => {
    const btn = document.querySelector('.topbtn')
    btn.addEventListener("click", () => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        })
    })
};

export default FtopBtn;